<template>
  <div class="card">
    <div class="card-image">
      <figure class="image is-square">
        <img :src="imageurl" :alt="`${title} image`" />
      </figure>
    </div>
    <div class="card-content">
      <div class="media">
        <div class="media-content">
          <p class="title is-4">{{ title }}</p>
          <p class="subtitle is-6">{{ price }}</p>
        </div>
      </div>

      <div class="content">
        <p>
          {{ description }}
        </p>
        <div class="buttons mt-4 is-centered">
          <a :href="link" target="_blank" class="button is-primary no-hover">
            <template v-if="free">
              <i class="fas fa-cloud-download-alt mr-2"></i>Download
            </template>
            <template v-else>
              <i class="fas fa-coins mr-2"></i>Spend Coins
            </template>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  // - components
  // - mixins
  // - props
  // - data
  // - computed
  // - watch
  // - lifecycle
  // - methods
  // - template

  export default {
    props: {
      title: {
        type: String,
        default: '',
      },
      imageurl: {
        type: String,
        default: '',
      },
      price: {
        type: String,
        default: '',
      },
      description: {
        type: String,
        default: '',
      },
      link: {
        type: String,
        default: '',
      },
      free: {
        type: Boolean,
        default: false,
      },
    },
  };
</script>
